import { html, css } from "lit";
import Hls from "hls.js";
import WarpElement from "@warp-ds/elements-core";
import { logVideoPause, logVideoPlay } from "../../../tracking/videoTracking.js";
import { ComponentMetaData } from "src/types/types-drEdition.js";
import { setupCustomElement } from "../../../lit-util.js";

const NAME = "hls-video";

class HlsVideo extends WarpElement {
	src!: string;
	id!: string;
	metaData!: ComponentMetaData;
	video!: HTMLVideoElement | null;
	observer!: IntersectionObserver | null;

	static get properties() {
		return {
			src: { type: String },
			id: { type: String },
			metaData: { type: Object },
		};
	}

	static styles = [
		css`
			:host {
				display: block;
				position: relative;
				width: 100%;
			}
			video {
				width: 100%;
				height: 400px;
			}
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static};
		`,
		...WarpElement.styles, // Adds the global styles
	] as unknown as never;

	firstUpdated() {
		const possiblyVideo = this.shadowRoot?.querySelector("video");
		if (possiblyVideo instanceof HTMLVideoElement) {
			this.video = possiblyVideo;
			this.initializeHls();
			this.setupIntersectionObserver();
			this.addVideoEventListeners();
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeVideoEventListeners();
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	initializeHls() {
		if ((Hls as any).isSupported()) {
			const hls = new (Hls as any)();
			hls.loadSource(this.src);
			hls.attachMedia(this.video);
		} else if (this.video?.canPlayType("application/vnd.apple.mpegurl")) {
			if (this.src) {
				this.video.src = this.src;
			}
		}
	}

	setupIntersectionObserver() {
		if ("IntersectionObserver" in window) {
			this.observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							this.video?.play();
						} else {
							this.video?.pause();
						}
					});
				},
				{
					root: null,
					threshold: 0.5,
				},
			);
			if (this.video) {
				this.observer.observe(this.video);
			}
		} else {
			this.video?.play();
		}
	}
	handlePlay = () => {
		if (!this.video) return;
		logVideoPlay(
			this.id,
			this.metaData?.vertical || "",
			this.metaData?.subvertical || "",
			this.video.currentTime,
			this.video.duration,
		);
	};
	handlePause = () => {
		if (!this.video) return;
		// Assuming logVideoPause is a global function or imported
		logVideoPause(
			this.id,
			this.metaData?.vertical || "",
			this.metaData?.subvertical || "",
			this.video.currentTime,
			this.video.duration,
		);
	};
	addVideoEventListeners() {
		const video = this.video;
		if (!video) return;

		video.addEventListener("play", this.handlePlay);
		video.addEventListener("pause", this.handlePause);
	}

	removeVideoEventListeners() {
		const video = this.video;
		if (!video) return;

		// The exact event listener functions must be referenced, so they need to be stored
		video.removeEventListener("play", this.handlePlay);
		video.removeEventListener("pause", this.handlePause);
	}

	render() {
		return html`<video muted autoplay controls controlsList="nodownload noremoteplayback noplaybackrate"></video> `;
	}
}

setupCustomElement(NAME, HlsVideo);
